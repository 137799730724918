define(['app'], function(app) {

  

  var globalBasketItemsCount = function() {
    var self = this;
    self.app = app;

    var _config = {
      itemsCountSelector: '[data-js-element=itemsCount]',
      suffixTextSelector: '[data-js-element=suffixText]',
      singularItem: app.utils.getProperty('singularItem', 'globalBasketItemsCount'),
      pluralItems: app.utils.getProperty('pluralItems', 'globalBasketItemsCount'),
    };

    var _init = function(element) {
      self.element = element;
      self.itemsCountElement = self.element.querySelector(self.config.itemsCountSelector);
      self.suffixTextElement = self.element.querySelector(self.config.suffixTextSelector);

      app.subscribe('ajaxBasket/updateGlobalBasketItemsCount', function(itemsCount) {
        self.updateBasketItemsCount(itemsCount);
        self.updateBasketSuffixText(itemsCount);
      });

      app.subscribe('globalBasketItemsCount/updateGlobalBasketItemsCount', function(itemsCount) {
        self.updateBasketItemsCount(itemsCount);
        self.updateBasketSuffixText(itemsCount);
      });
      return self;
    };

    var _updateBasketItemsCount = function(itemsCount) {
      self.itemsCountElement.textContent = itemsCount;
    };

    var _updateBasketSuffixText = function(itemsCount) {
      self.suffixTextElement.textContent = itemsCount === 1 ? self.config.singularItem : self.config.pluralItems;
    };

    self.config = _config;
    self.init = _init;
    self.updateBasketItemsCount = _updateBasketItemsCount;
    self.updateBasketSuffixText = _updateBasketSuffixText;
  };

  return globalBasketItemsCount;
});
